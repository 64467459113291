import React, { Component } from 'react';
import './css/index.css';

class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className="container">
          {/* <div className="left"></div>
          <div className="right"></div> */}
          
          <div className="ad"></div>
        </div>
      </div>
    );
  }
}

export default Footer;